/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
    color: #fff;
    /* background-color: #1b6ec2; */
    background-color: #dfaf4b !important;
    border-color: #ac873a !important;
    /* border-color: #1861ac; */
}

    .btn-primary:hover {
        background-color: #ac873a !important;
        border-color: #ac873a !important;
    }

    .btn-primary:active,
    .btn-primary.active,
    .open > .dropdown-toggle.btn-primary {
        color: #fff;
        background-color: #ac873a !important;
        border-color: #ac873a !important;
    }

        .btn-primary:active:hover,
        .btn-primary.active:hover,
        .open > .dropdown-toggle.btn-primary:hover,
        .btn-primary:active:focus,
        .btn-primary.active:focus,
        .open > .dropdown-toggle.btn-primary:focus,
        .btn-primary:active.focus,
        .btn-primary.active.focus,
        .open > .dropdown-toggle.btn-primary.focus {
            color: #fff;
            background-color: #ac873a !important;
            border-color: #ac873a !important;
        }

    .btn-primary:active,
    .btn-primary.active,
    .open > .dropdown-toggle.btn-primary {
        background-image: none;
    }

    .btn-primary.disabled:hover,
    .btn-primary[disabled]:hover,
    fieldset[disabled] .btn-primary:hover,
    .btn-primary.disabled:focus,
    .btn-primary[disabled]:focus,
    fieldset[disabled] .btn-primary:focus,
    .btn-primary.disabled.focus,
    .btn-primary[disabled].focus,
    fieldset[disabled] .btn-primary.focus {
        background-color: #ac873a !important;
        border-color: #ac873a !important;
    }


.nav-button {
    font-size: 32px;
    color: #dfaf4b !important;
}

#page-numbers {
  list-style: none;
  display: flex;
}

#page-numbers > li {
  margin-right: 0.3em;
  color: blue;
  user-select: none;
  cursor: pointer;
}
